<template>
  <v-card :class="cardClass" color="white" outlined elevation="1" :loading="loading">
    <v-toolbar color="primary darken-1" dark flat elevation="1" :class="toolbarClass">
      <v-toolbar-title>
        <div v-if="title">{{title}}</div>
        <span class="text-caption" v-if="subTitle">{{subTitle}}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-avatar v-if="icon" class="data-card-avatar" color="primary lighten-1" size="54">
        <v-icon dark>{{icon}}</v-icon>
      </v-avatar>
    </v-toolbar>
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'pibot-data-card',
  props: {
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    icon: {
      type: String,
      default: 'info'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cardClass () {
      return {
        'pibot-data-card': true,
        'toolbar-auto-height': this.title && this.subTitle
      }
    },
    toolbarClass () {
      return {
        'py-2': this.title && this.subTitle
      }
    }
  }
}
</script>

<style>
.pibot-data-card .data-card-avatar {
  transform: translate(0, calc(-50% - 0.25rem))
}

.pibot-data-card.toolbar-auto-height .data-card-avatar {
  transform: translate(0, calc(-50% - 0.75rem))
}

.pibot-data-card.toolbar-auto-height .v-toolbar,
.pibot-data-card.toolbar-auto-height .v-toolbar__content {
  height: unset !important;
}
</style>
