<template>
  <section id="predictions">
    <pibot-section-heading><slot>Predictions</slot></pibot-section-heading>

    <v-layout align-start justify-space-between wrap>
      <!-- Degradation Prediction Card -->
      <v-flex xs12 md8>
        <pibot-data-card
          :icon="'scatter_plot'"
          :title="'Degradation Prediction'"
          :subTitle="'Corrosion and Fitness for Service'"
          :class="cardClass"
        >
          <v-list
            v-for="(p, key) in predictions"
            :key="key"
            subheader
            dense
          >
            <span class="font-weight-medium text-capitalize">{{key | normalized}}:</span>

            <template v-for="(item) in predictions[key]">
              <div :key="item.id">
                <v-list-item>
                  <v-list-item-content>
                    <span v-text="item.title" class="font-weight-regular text-body-2"/>
                  </v-list-item-content>

                  <v-layout align-end justify-end >
                    <div class="mr-2 font-weight-medium">{{item.value}}</div>
                    <v-layout align-start justify-end column fill-height d-inline-flex shrink>
                      <small v-if="item.uncertainty" class="grey--text">&plusmn;{{item.uncertainty}}</small>
                      <small v-if="item.unit">{{item.unit}}</small>
                    </v-layout>
                  </v-layout>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </template>
          </v-list>
        </pibot-data-card>
      </v-flex>

      <!-- Lifetime Prediction Card -->
      <v-flex xs12 md4>
        <pibot-data-card
          :icon="'bar_chart'"
          :title="'Lifetime Prediction'"
          :subTitle="'Yearly Likelihood of Reaching Threshold'"
          :class="cardClass"
        >
          <span class="text-subtitle-2">Yearly likelihood</span>
          <p>
            The following indicates for each year the estimated propability that this section of pipe will cross the FFS threshold in or before the given year.
          </p>

          <v-data-table
            :dense="$vuetify.breakpoint.smAndUp"
            disable-filtering
            disable-pagination
            hide-default-footer
            sort-by="year"
            must-sort
            :headers="headers.yearly"
            :items="calculations.yearly_likelihood"
            :items-per-page="9999"
          >
            <!-- Risk Chips -->
            <template v-slot:[`item.likelihood`]="{ item }">
              <pibot-risk-chip :risk="item.likelihood" class="my-1" small />
            </template>
          </v-data-table>
        </pibot-data-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import SectionHeading from '@/components/typography/headings/SectionHeading'
import DataCard from '@/components/cards/DataCard'
import RiskChip from '@/components/chips/RiskChip'

export default {
  name: 'pibot-asset-predictions',
  components: {
    'pibot-section-heading': SectionHeading,
    'pibot-data-card': DataCard,
    'pibot-risk-chip': RiskChip
  },
  data () {
    return {
      headers: {
        yearly: [
          {
            text: 'Year',
            align: 'left',
            sortable: true,
            value: 'year'
          },
          {
            text: 'Likelihood',
            align: 'right',
            sortable: false,
            value: 'likelihood'
          }
        ]
      },
      predictions: {
        internal_corrosion: [
          {
            id: 'growth_rate',
            title: 'Maximum growth rate',
            value: 0.15,
            uncertainty: 0.04,
            unit: 'mm/yr'
          },
          {
            id: 'calculation_interval',
            title: 'Growth rate calculation interval',
            value: 4.8,
            unit: 'yr'
          },
          {
            id: 'first_feature',
            title: 'First feature to exceed critical dimensions',
            value: 'ID 234 @ 1260 m (35% wt)'
          },
          {
            id: 'ffs_time',
            title: 'Time to exceed critical dimensions',
            value: 3.6,
            uncertainty: 0.8,
            unit: 'yr'
          },
          {
            id: 'remaining_wall_thickness',
            title: 'Remaining Wall Thickness to exceed critical dimensions',
            value: 3.7,
            uncertainty: 0.1,
            unit: 'mm'
          }
        ],
        external_corrosion: [
          {
            id: 'growth_rate',
            title: 'Maximum growth rate',
            value: 0.38,
            uncertainty: 0.07,
            unit: 'mm/yr'
          },
          {
            id: 'calculation_interval',
            title: 'Growth rate calculation interval',
            value: 3.1,
            unit: 'yr'
          },
          {
            id: 'first_feature',
            title: 'First feature to exceed critical dimensions',
            value: 'ID 168 @ 547 m (47% wt)'
          },
          {
            id: 'ffs_time',
            title: 'Time to exceed critical dimensions',
            value: 2.4,
            uncertainty: 0.6,
            unit: 'yr'
          },
          {
            id: 'remaining_wall_thickness',
            title: 'Remaining Wall Thickness to exceed critical dimensions',
            value: 3.0,
            uncertainty: 0.1,
            unit: 'mm'
          }
        ],
        assessment_details: [
          {
            id: 'inspections_used',
            title: 'Inspections used',
            value: 'ILI 2012, ILI 2017'
          },
          {
            id: 'assessment_pressure',
            title: 'Assessment pressure',
            value: '10',
            unit: 'bar'
          },
          {
            id: 'assessment_pressure2',
            title: '',
            value: '7',
            unit: '%SMYS'
          },
          {
            id: 'assessment_method',
            title: 'Assessment method (Axial)',
            value: 'ASME B31.G'
          },
          {
            id: 'assessment_method2',
            title: 'Assessment method (Circumferential)',
            value: 'Kaster'
          }
        ],
        first_estimated_repair: [
          {
            id: 'first_estimated_repair0',
            title: 'Date',
            value: 'April 2021'
          },
          {
            id: 'first_estimated_repair1',
            title: 'Anomaly',
            value: 'ID 168 @ 547 m (47% wt)'
          },
          {
            id: 'first_estimated_repair2',
            title: 'Type',
            value: 'External corrosion'
          }
        ]
      },
      calculations: {
        yearly_likelihood: [
          {
            year: 2018,
            likelihood: 'very low'
          },
          {
            year: 2019,
            likelihood: 'very low'
          },
          {
            year: 2020,
            likelihood: 'low'
          },
          {
            year: 2021,
            likelihood: 'low'
          },
          {
            year: 2022,
            likelihood: 'low'
          },
          {
            year: 2023,
            likelihood: 'low'
          },
          {
            year: 2024,
            likelihood: 'low'
          },
          {
            year: 2025,
            likelihood: 'medium'
          },
          {
            year: 2026,
            likelihood: 'medium'
          },
          {
            year: 2027,
            likelihood: 'high'
          },
          {
            year: 2028,
            likelihood: 'very high'
          }
        ]
      }
    }
  },
  computed: {
    cardClass () {
      return ['mx-3', 'my-4']
    }
  },
  filters: {
    normalized (val) {
      return val.split('_').join(' ')
    }
  }
}
</script>
